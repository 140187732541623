import { useCallback, useId, useRef, useState } from 'react';
import { Icon } from '@pelando/components';
import { CircleInfo } from '@pelando/fontawesome/icons';
import { Tooltip } from '@pelando/components/components/Tooltip/index';
import {
  FloatingElementHorizontalPosition,
  FloatingElementVerticalPosition,
} from '@pelando/components/components/FloatingElementWrapper/consts';
import Translation from '@/presentation/components/Translation';
import { useTranslation } from '@/presentation/hooks/useTranslation';

import { useEmailConfirmationGuardModal } from '@/presentation/hooks/useEmailConfirmationGuardModal';
import { useCurrentUserCache } from '@/presentation/hooks/useCurrentUserCache';
import {
  Info,
  InfoContainer,
  Title,
  Container,
  RedeemButton,
  Price,
  HelpButton,
  TooltipContent,
} from './style';
import { useCashbackRedeemModal } from '../CashbackRedeemModal';
import { useCashbackFormModal } from '../CashbackForm';

type BalanceProps = {
  className?: string;
  balanceAvailable?: number;
  withdrawalAllowed?: boolean;
};

const TOOL_TIP_OPEN_TO = {
  vertical: FloatingElementVerticalPosition.BOTTOM,
  horizontal: FloatingElementHorizontalPosition.MIDDLE,
};

export const Balance = ({
  className,
  balanceAvailable,
  withdrawalAllowed,
}: BalanceProps) => {
  const id = useId();
  const { t } = useTranslation('cashbackBalance');

  const { loggedUser } = useCurrentUserCache();

  const { emailConfirmed } = loggedUser || {};

  const { showConfirmEmail } = useEmailConfirmationGuardModal({
    isCashbackPage: true,
  });

  const { showFormModal } = useCashbackFormModal();

  const { showRedeemModal } = useCashbackRedeemModal();

  const handleEmailGuardModal = useCallback(() => {
    if (!emailConfirmed) {
      showConfirmEmail();
      return;
    }

    if (!loggedUser?.bankAccountDetails) {
      showFormModal();
      return;
    }

    showRedeemModal();
  }, [
    emailConfirmed,
    loggedUser?.bankAccountDetails,
    showConfirmEmail,
    showRedeemModal,
    showFormModal,
  ]);

  const [isTooltipVisible, setIsTooltipVisible] = useState(false);
  const helpButtonRef = useRef<HTMLButtonElement>(null);

  const handleHelpButtonClick = useCallback(() => {
    setIsTooltipVisible((prev) => !prev);
  }, []);

  const handleHelpButtonBlur = useCallback(() => {
    setIsTooltipVisible(false);
  }, []);

  const handleHelpButtonKeyDown = useCallback((e: React.KeyboardEvent) => {
    if (e.key === 'Escape') {
      setIsTooltipVisible(false);
    }
  }, []);

  const tooltipId = `cashback-balance-info-tooltip-${id}`;

  const formattedBalance = balanceAvailable
    ? balanceAvailable.toFixed(2).replace('.', ',')
    : '0,00';

  return (
    <Container className={className}>
      <InfoContainer>
        <Info>
          <Title>{t('cashback-balance-available-balance-label')}</Title>
          <Tooltip
            openTo={TOOL_TIP_OPEN_TO}
            childrenRef={helpButtonRef}
            content={
              <TooltipContent id={tooltipId} role="tooltip" aria-live="polite">
                <Translation
                  hasTag
                  translation={t('cashback-balance-info-tooltip-content')}
                >
                  Você precisa ter no mínino <br /> R$ 20 para solicitar o
                  resgate
                </Translation>
              </TooltipContent>
            }
            isVisible={isTooltipVisible}
          >
            <HelpButton
              ref={helpButtonRef}
              onClick={handleHelpButtonClick}
              onBlur={handleHelpButtonBlur}
              onKeyDown={handleHelpButtonKeyDown}
              aria-label={t('cashback-balance-info-tooltip-button-label')}
              aria-haspopup="true"
              aria-controls={tooltipId}
              aria-expanded={isTooltipVisible}
            >
              <Icon icon={CircleInfo} />
            </HelpButton>
          </Tooltip>
        </Info>
        <Price>
          R$ <strong>{formattedBalance}</strong>
        </Price>
      </InfoContainer>
      <RedeemButton
        disabled={!withdrawalAllowed}
        onClick={() => handleEmailGuardModal()}
      >
        {t('cashback-balance-redeem-button-text')}
      </RedeemButton>
    </Container>
  );
};
