import { getFlag, UnleashFlags } from '@/infra/unleash';

export const isAdPlacementDisabled = () =>
  getFlag(UnleashFlags.DISABLE_AD_PLACEMENT);

export const isAutomaticallyFollowOfferFeatureEnabled = () =>
  getFlag(UnleashFlags.AUTOMATICALLY_FOLLOW_OFFER);

export const shouldActivateFeedRecombee = () =>
  getFlag(UnleashFlags.FEED_ACTIVE_RECOMBEE);

export const shouldActivateFeedRecombeeLoggedOut = () =>
  getFlag(UnleashFlags.FEED_ACTIVE_RECOMBEE_LOGGEDOUT);

export const isCommentsSuggestionsEnabled = () =>
  getFlag(UnleashFlags.WEB_COMMENT_SUGGESTIONS);

export const isShowAppInstallIncentivesEnabled = () =>
  getFlag(UnleashFlags.WEB_INCENTIVE_STANDARD_TOAST);

export const isShowBottomSheetAppInstallIncentivesEnabled = () =>
  getFlag(UnleashFlags.WEB_INCENTIVE_BOTTOM_SHEET_DEALS);

export const isOnboardingUserTypeEnabled = () =>
  getFlag(UnleashFlags.ONBOARDING_USER_TYPE_ACTIVE);

export const isOnboardDealsEnabled = () =>
  getFlag(UnleashFlags.WEB_ONBOARD_DEALS);

export const isShowWelcomeStageOnboardDeals = () =>
  getFlag(UnleashFlags.WEB_WELCOME_STAGE_ONBOARD_DEALS);

export const isProductIndexingEnabled = () =>
  getFlag(UnleashFlags.ENABLE_PRODUCT_INDEXING);

export const isActivatedCookiesEnabled = () =>
  getFlag(UnleashFlags.WEB_COOKIES_ACTIVE);

export const isFixedCardTopFeed = () => getFlag(UnleashFlags.WEB_CARD_FIX_TOP);

export const isBlackFridayHeaderEnabled = () =>
  getFlag(UnleashFlags.WEB_HEADER_BLACK);

export const isProductFilterImageUrlWorkaroundEnabled = () =>
  getFlag(UnleashFlags.WEB_PRODUCT_FILTER_IMAGE_URL_WORKAROUND);

export const isComparatorHomeEnabled = () =>
  getFlag(UnleashFlags.WEB_ENABLE_COMPARATOR_HOME);

export const isRecommendationHottestDealsEnabled = () =>
  getFlag(UnleashFlags.WEB_DEALS_RECOMMENDATION);

export const isIncentiveLoginBannerComments = () =>
  getFlag(UnleashFlags.WEB_INCENTIVE_LOGIN_BANNER_DEALS);

export const isIncentiveLoginBannerEnabled = () =>
  getFlag(UnleashFlags.WEB_INCENTIVE_LOGIN_BANNER_HOME);

export const isIncentiveLoginBannerSideEnabled = () =>
  getFlag(UnleashFlags.WEB_INCENTIVE_LOGIN_BANNER_SIDE);

export const isDealsComparatorPriceEnabled = () =>
  getFlag(UnleashFlags.DEALS_COMPARATOR_PRICE);

export const isUserbackButtonDisabled = () =>
  getFlag(UnleashFlags.WEB_USERBACK_BUTTON_DISABLED);

export const getIsBlogPageEnabled = () =>
  getFlag(UnleashFlags.WEB_ENABLE_BLOG_PAGE);

export const isIncentiveAlertCreateEnabled = () =>
  getFlag(UnleashFlags.WEB_ENABLE_INCENTIVE_ALERT_CREATE);

export const isDealFeedInfiniteEnabled = () =>
  getFlag(UnleashFlags.WEB_DEALS_FEED_INFINITE);

export const isEnabledPostDealV2 = () =>
  getFlag(UnleashFlags.WEB_ENABLED_POST_DEAL_V2);

export const isBlackFridayLPEnabled = () =>
  getFlag(UnleashFlags.WEB_ENABLE_BF_LANDING_PAGE);

export const isNewFeedPaginationEnabled = () =>
  getFlag(UnleashFlags.WEB_ENABLE_NEW_FEED_PAGINATION);

export const isOnboardingIncentiveAppEnabled = () =>
  getFlag(UnleashFlags.WEB_ONBOARDING_INCENTIVE_APP);

export const isNewProductCardOnSearchABTestEnabled = () =>
  getFlag(UnleashFlags.WEB_ENABLE_NEW_PRODUCT_CARD_ON_SEARCH_AB_TEST) === 'new';

export const isTabDealsEnabled = () => getFlag(UnleashFlags.WEB_TAB_DEALS);

export const isIncentiveAppHomeDeals = () =>
  getFlag(UnleashFlags.WEB_BANNER_INCENTIVE_APP_HOME_DEALS);

export const isBlackFridayInternalLinksEnabled = () =>
  getFlag(UnleashFlags.WEB_ENABLE_BF_INTERNAL_LINKS);

export const isNewSearchLabelsEnabled = () =>
  getFlag(UnleashFlags.WEB_SEARCH_LABELS_AB_TEST) === 'new';

export const getProductDealsLimit = () =>
  parseInt(getFlag(UnleashFlags.WEB_PRODUCT_DEALS_LIMIT), 10);

export const isIncentiveFirstComment = () =>
  getFlag(UnleashFlags.WEB_INCENTIVE_FIRST_COMMENT);

export const isFeedTabRecommendedEnabled = () =>
  getFlag(UnleashFlags.WEB_FEED_TAB_RECOMMENDED);

export const isHeaderCommunitiesDisabled = () =>
  getFlag(UnleashFlags.WEB_DISABLE_COMMUNITIES);

export const getDopelandoTime = () =>
  +getFlag(UnleashFlags.WEB_DOPELANDO_TIME) || 500;

export const isNewAlertPageEnabled = () =>
  getFlag(UnleashFlags.WEB_NEW_ALERT_PAGE);

export const isCommentReplyOnProductPageEnabled = () =>
  getFlag(UnleashFlags.WEB_ENABLE_COMMENT_REPLY_ON_PRODUCT_PAGE);

export const isFirebaseWebPushEnabled = () =>
  getFlag(UnleashFlags.WEB_FIREBASE_PUSH_ENABLED);

export const getN1DealsLimit = () =>
  parseInt(getFlag(UnleashFlags.WEB_N1_DEALS_LIMIT), 10) || 10;

export const isProductFeaturesEnabled = () =>
  getFlag(UnleashFlags.WEB_ENABLE_PRODUCT_FEATURES);

export const isSeasonalFeedEnabled = () =>
  getFlag(UnleashFlags.WEB_ENABLE_SEASONAL_FEED);

export const isCashbackEnabled = () =>
  getFlag(UnleashFlags.WEB_ENABLE_BLACK_FRIDAY_CAMPAIGN);

export const isCashbackClaimEnabled = () =>
  getFlag(UnleashFlags.WEB_ENABLE_CASHBACK_CLAIM);

export const isSEOLinkWorkaroundEnabled = () =>
  getFlag(UnleashFlags.WEB_ENABLE_SEO_LINK_WORKAROUND);

export const isCouponSEOForAllStoresEnabled = () =>
  getFlag(UnleashFlags.WEB_ENABLE_COUPONS_SEO_FOR_ALL_STORES);
